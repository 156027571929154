var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "Wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { float: "left" },
              attrs: {
                "label-position": "right",
                "label-width": "110px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "错峰卡名称",
                    prop: "staggeredName",
                    rules: _vm.rules.staggeredName,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入错峰卡",
                        "remote-method": _vm.querySearchAsync2,
                        loading: _vm.loading,
                      },
                      on: { change: _vm.selectStaggeredName },
                      model: {
                        value: _vm.formInline.staggeredName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "staggeredName", $$v)
                        },
                        expression: "formInline.staggeredName",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.staggeredId,
                        attrs: {
                          label: item.staggeredName,
                          value: item.staggeredId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号码", prop: "numberName" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                      attrs: { rules: _vm.rules.numberName },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.numberName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "numberName", $$v)
                            },
                            expression: "formInline.numberName",
                          },
                        },
                        _vm._l(_vm.platerNumberLists, function (item) {
                          return _c("el-option", {
                            key: item.numberId,
                            attrs: {
                              label: item.numberName,
                              value: item.numberName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        width: "210px",
                        padding: "0 20px 0 5px",
                        display: "inline-block",
                      },
                      attrs: {
                        prop: "plateNumber",
                        rules: [
                          {
                            required: true,
                            message: "请输入车牌号码",
                            trigger: "change",
                          },
                          {
                            required: true,
                            validator: _vm.checkNumber,
                            trigger: "blur change",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: 7, placeholder: "请输入车牌号" },
                        model: {
                          value: _vm.formInline.plateNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "plateNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.plateNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" 车牌颜色 "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100px", display: "inline-block" },
                      attrs: {
                        rules: [
                          {
                            required: true,
                            message: "请选择车牌颜色",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.changePlate(
                                _vm.formInline,
                                _vm.formInline.plateColor
                              )
                            },
                          },
                          model: {
                            value: _vm.formInline.plateColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "plateColor",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.plateColor",
                          },
                        },
                        _vm._l(_vm.colorList, function (v) {
                          return _c("el-option", {
                            key: v.code,
                            attrs: { label: v.desc, value: v.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.phone_number"),
                    prop: "phoneNumber",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "217px" },
                    attrs: { maxlength: 11, placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formInline.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "phoneNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.phoneNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "有效期", required: true } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      "value-format": "yyyy-MM-dd",
                      clearable: true,
                      type: "daterange",
                      "range-separator": "-",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.rangeDate,
                      callback: function ($$v) {
                        _vm.rangeDate = $$v
                      },
                      expression: "rangeDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "left", "margin-left": "8.5%" } },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "primary" },
              on: { click: _vm.addEdit },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "88px" },
              attrs: { type: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("span", { staticClass: "title_icon" }),
      _vm._v("添加订单"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }