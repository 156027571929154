<template>
  <div class="page1">
    <div class="content">
      <h2 class="title"><span class="title_icon"></span>添加订单</h2>
      <div class="Wrapper">
        <el-form
          label-position="right"
          label-width="110px"
          :model="formInline"
          style="float: left"
          :rules="rules"
          ref="form"
        >
          <el-form-item
            label="错峰卡名称"
            prop="staggeredName"
            :rules="rules.staggeredName"
          >
            <el-select
              v-model="formInline.staggeredName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入错峰卡"
              :remote-method="querySearchAsync2"
              :loading="loading"
              @change="selectStaggeredName"
            >
              <el-option
                v-for="item in options"
                :key="item.staggeredId"
                :label="item.staggeredName"
                :value="item.staggeredId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车牌号码" prop="numberName">
            <el-form-item
              :rules="rules.numberName"
              style="width: 100px; display: inline-block"
            >
              <el-select v-model="formInline.numberName" placeholder="请选择">
                <el-option
                  v-for="item in platerNumberLists"
                  :key="item.numberId"
                  :label="item.numberName"
                  :value="item.numberName"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              prop="plateNumber"
              :rules="[
                {
                  required: true,
                  message: '请输入车牌号码',
                  trigger: 'change',
                },
                {
                  required: true,
                  validator: checkNumber,
                  trigger: 'blur change',
                },
              ]"
              style="width: 210px; padding: 0 20px 0 5px; display: inline-block"
            >
              <el-input
                :maxlength="7"
                v-model.trim="formInline.plateNumber"
                placeholder="请输入车牌号"
              ></el-input>
            </el-form-item>
            车牌颜色
            <el-form-item
              :rules="[
                {
                  required: true,
                  message: '请选择车牌颜色',
                  trigger: 'change',
                },
              ]"
              style="width: 100px; display: inline-block"
            >
              <el-select
                v-model.trim="formInline.plateColor"
                placeholder="请选择"
                @change="changePlate(formInline, formInline.plateColor)"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  v-for="v in colorList"
                  :key="v.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="phoneNumber">
            <el-input
              style="width: 217px"
              :maxlength="11"
              v-model.trim="formInline.phoneNumber"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效期" :required="true">
            <el-date-picker
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              :clearable="true"
              v-model="rangeDate"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 8.5%">
        <el-button type="primary" @click="addEdit" style="width: 88px"
          >{{ $t('button.preservation') }}</el-button
        >
        <el-button type="" @click="$router.go(-1)" style="width: 88px"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>
  <script>
import { dateFormat, formatWeekDate } from "@/common/js/public";
const now = new Date();
// let start = new Date().format("yyyy-MM-dd");
export default {
  name: "staggeredOrderAdd",
  data() {
    let checkPhone = (rule, value, callback) => {
      if (!value) {
        callback("请输入手机号");
        return false;
      } else if (value && !/^\d{11}$/.test(value)) {
        callback("手机号格式不正确");
        return false;
      } else {
        return callback();
      }
    };
    let checkStaggeredName = (rule, value, callback) => {
      if (!this.formInline.staggeredName) {
        callback("选择正确的错峰卡");
        return false;
      } else {
        return callback();
      }
    };
    return {
      parkCardIsActive: true,
      isNowDate: true,
      ruleStartTime: "",
      ruleList: [],
      staggeredParkList: [],
      loading: false,
      scopeClick: "",
      scopeDesc: "",
      operationId: "",
      formInline: {
        staggeredName: "",
        staggeredId: "",
        numberId: "",
        plateNumber: "",
        plateColor: "",
        phoneNumber: "",
        useRangeDesc: "",
        numberName: "",
      },
      staggeredNameList: [],
      colorList: [],
      platerNumberLists: [
        {
          numberId: "11",
          numberName: "京",
        },
        {
          numberId: "12",
          numberName: "津",
        },
        {
          numberId: "13",
          numberName: "冀",
        },
        {
          numberId: "14",
          numberName: "晋",
        },
        {
          numberId: "15",
          numberName: "蒙",
        },
        {
          numberId: "21",
          numberName: "辽",
        },
        {
          numberId: "22",
          numberName: "吉",
        },
        {
          numberId: "23",
          numberName: "黑",
        },
        {
          numberId: "31",
          numberName: "沪",
        },
        {
          numberId: "32",
          numberName: "苏",
        },
        {
          numberId: "33",
          numberName: "浙",
        },
        {
          numberId: "34",
          numberName: "皖",
        },
        {
          numberId: "35",
          numberName: "闽",
        },
        {
          numberId: "36",
          numberName: "赣",
        },
        {
          numberId: "37",
          numberName: "鲁",
        },
        {
          numberId: "41",
          numberName: "豫",
        },
        {
          numberId: "42",
          numberName: "鄂",
        },
        {
          numberId: "43",
          numberName: "湘",
        },
        {
          numberId: "44",
          numberName: "粤",
        },
        {
          numberId: "45",
          numberName: "桂",
        },
        {
          numberId: "46",
          numberName: "琼",
        },
        {
          numberId: "50",
          numberName: "渝",
        },
        {
          numberId: "51",
          numberName: "川",
        },
        {
          numberId: "52",
          numberName: "黔",
        },
        {
          numberId: "53",
          numberName: "滇",
        },
        {
          numberId: "54",
          numberName: "藏",
        },
        {
          numberId: "61",
          numberName: "陕",
        },
        {
          numberId: "62",
          numberName: "甘",
        },
        {
          numberId: "63",
          numberName: "青",
        },
        {
          numberId: "64",
          numberName: "宁",
        },
        {
          numberId: "65",
          numberName: "新",
        },
        {
          numberId: "71",
          numberName: "台",
        },
        {
          numberId: "81",
          numberName: "港",
        },
        {
          numberId: "82",
          numberName: "澳",
        },
        {
          numberId: "90",
          numberName: "外",
        },
      ],
      rules: {
        phoneNumber: [{ validator: checkPhone, trigger: "change" }],
        staggeredName: [
          {
            trigger: "change",
            required: true,
            message: "请输入错峰卡名称",
            validator: checkStaggeredName,
          },
        ],
        numberName: [
          {
            required: true,
            message: "请输入车牌号",
            trigger: "change",
          },
        ],
        plateNumber: [
          {
            required: true,
            message: "请输入车牌号码",
            trigger: "change",
          },
          { required: true, validator: this.checkNumber, trigger: "change" },
        ],
        rangeDate: [
          {
            required: true,
            message: "请选择错峰日期",
            trigger: "change",
          },
        ],
      },
      options: [],
      rangeDate: "",
      pickerOptions: {
        disabledDate: (val) => {
          // const now = new Date().getTime();
          return (
            val.getTime() < now - 24 * 60 * 60 * 1000 ||
            val.getTime() > new Date(this.pikerEndTime).getTime()
          );
        },
      },
    };
  },
  methods: {
    checkNumber(rule, value, callback) {
      let rel = "";
      if (
        value == this.formInline.plateNumber &&
        (this.formInline.numberId == 0 || this.formInline.numberId == 1)
      ) {
        // 蓝牌 黄牌
        rel = /^[A-Z]{1}[A-Z0-9]{5}$/;
        if (rel.test(value)) {
          callback();
        } else {
          callback("请输入正确的车牌号");
        }
      } else if (
        value == this.formInline.plateNumber &&
        (this.formInline.numberId == 2 || this.formInline.numberId == 3)
      ) {
        console.log("23", this.formInline);
        // 2绿牌、3黄绿牌
        rel = /^[A-Z]{1}[A-Z0-9]{6}$/;
        if (rel.test(value)) {
          callback();
        } else {
          callback("请输入正确的车牌号");
        }
      } else if (
        value == this.formInline.plateNumber &&
        (this.formInline.numberId == 4 || this.formInline.numberId == 5)
      ) {
        console.log("45", this.formInline);
        // 4黑牌、5白牌
        rel = /^[A-Z]{1}[A-Z0-9]{5,6}$/;
        if (rel.test(value)) {
          callback();
        } else {
          callback("请输入正确的车牌号");
        }
      }
    },
    querySearchAsync2(queryString, cb) {
      console.log(queryString, "-queryString");
      this.loading = false;
      let opt = {
        method: "get",
        url: "/acb/2.0/staggered/dropDown",
        data: {
          staggeredName: queryString,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          this.options = res.value;
          // res.value.forEach((v) => {
          //   v.value = v.staggeredName;
          // });
          // cb(res.value);
        }
      });
    },
    changePlate(val, color) {
      console.log("hahah", color);
      val.numberId = color;
      val.plateColor = color;
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    selectStaggeredName(val) {
      this.formInline.staggeredId = val;
      // 日期范围不能超过设置时间
      this.options.forEach((e) => {
        if (this.formInline.staggeredId == e.staggeredId) {
          this.pikerEndTime = e.endDate;
        }
      });
      console.log(this.pikerEndTime, "------------");
    },
    addEdit() {
      let url = "/acb/2.0/staggeredOrder/add";
      const data = {
        plateNumber: this.formInline.numberName + this.formInline.plateNumber,
        plateColor: this.formInline.plateColor,
        staggeredId: this.formInline.staggeredId,
        phoneNumber: this.formInline.phoneNumber,
        startTime: this.rangeDate[0],
        endTime: this.rangeDate[1],
      };
      console.log(data, "data------------");
      this.$refs.form.validate((valid) => {
        if (valid) {
          let method = "post";
          const opt = {
            url,
            method,
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            },
            data,
            success: (res) => {
              if (res.state == 0) {
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: res.desc,
                });
              } else {
                this.$alert(res.desc, "提示");
              }
            },
            fail: (err) => {},
          };
          this.$request(opt);
        }
      });
    },
  },
  components: {},
  created() {
    this.getColor();
  },
};
</script>
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="stylus" rel="stylesheet/stylus">
  .Wrapper {
    overflow: hidden;
  }

  .breadcrumb {
    height: 35px;
  }

  .content {
    background: #FFFFFF;
    overflow: hidden;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
    padding: 20px;

    .range-wrapper {
      line-height: 20px;
      padding: 10px;
      background: #ecf5ff;
      margin-top: 10px;
      border-radius: 8px;
      min-width: 300px;
    }

    .button-style {
      padding: 4px 10px;
      margin-left: 5px;
    }

    .icon-style {
      font-size: 30px;
      vertical-align: middle;
    }
  }
</style>
